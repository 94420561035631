import { RSAPCDPackage } from "@pcd/rsa-pcd";
import JSONBig from "json-bigint";
import { v4 as uuid } from "uuid";
import { RSATicketPCD, RSATicketPCDTypeName } from "./RSATicketPCD";
import { getTicketData } from "./utils";
export let initArgs;
async function init(args) {
    initArgs = args;
}
export async function prove(args) {
    if (!initArgs) {
        throw new Error("package not initialized");
    }
    if (!args.rsaPCD.value?.pcd) {
        throw new Error("missing rsa pcd");
    }
    const deserialized = await RSAPCDPackage.deserialize(args.rsaPCD.value?.pcd);
    const valid = await RSAPCDPackage.verify(deserialized);
    if (!valid) {
        throw new Error("supplied rsa pcd is not valid");
    }
    const id = args.id.value ?? uuid();
    return new RSATicketPCD(id, {}, { rsaPCD: deserialized });
}
export async function verify(pcd) {
    if (!initArgs) {
        throw new Error("package not initialized");
    }
    try {
        const valid = await RSAPCDPackage.verify(pcd.proof.rsaPCD);
        return valid;
    }
    catch (e) {
        return false;
    }
}
export async function serialize(pcd) {
    if (!initArgs) {
        throw new Error("package not initialized");
    }
    const serializedRSAPCD = await RSAPCDPackage.serialize(pcd.proof.rsaPCD);
    return {
        type: RSATicketPCDTypeName,
        pcd: JSONBig().stringify({
            id: pcd.id,
            rsaPCD: serializedRSAPCD
        })
    };
}
export async function deserialize(serialized) {
    if (!initArgs) {
        throw new Error("package not initialized");
    }
    const deserializedWrapper = JSONBig().parse(serialized);
    const deserializedRSAPCD = await RSAPCDPackage.deserialize(deserializedWrapper.rsaPCD.pcd);
    return new RSATicketPCD(deserializedWrapper.id, {}, { rsaPCD: deserializedRSAPCD });
}
export function getDisplayOptions(pcd) {
    if (!initArgs) {
        throw new Error("package not initialized");
    }
    const ticketData = getTicketData(pcd);
    let header = "Ticket";
    if (ticketData.isRevoked) {
        header = `[CANCELED] ${ticketData.eventName} (${ticketData.ticketName})`;
    }
    else if (ticketData.isConsumed) {
        header = `[SCANNED] ${ticketData.eventName} (${ticketData.ticketName})`;
    }
    else if (ticketData.eventName && ticketData.ticketName) {
        header = `${ticketData.eventName} (${ticketData.ticketName})`;
    }
    return {
        header: header,
        displayName: "ticket-" + pcd.id.substring(0, 4)
    };
}
/**
 * PCD-conforming wrapper to sign messages using an RSA keypair.
 */
export const RSATicketPCDPackage = {
    name: RSATicketPCDTypeName,
    getDisplayOptions,
    init,
    prove,
    verify,
    serialize,
    deserialize
};
