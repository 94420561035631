import { jsx as _jsx } from "react/jsx-runtime";
import styled from "./StyledWrapper";
export function Spacer({ w, h }) {
    const width = w && `${w}px`;
    const height = h && `${h}px`;
    return _jsx("div", { style: { width, height, userSelect: "none" } });
}
export const Separator = styled.div `
  box-sizing: border-box;
  background-color: var(--primary-lite);
  width: 100%;
  height: 2px;
  border-radius: 99px;
  margin: 16px 0px;
  user-select: none;
`;
export const FieldLabel = styled.span `
  font-weight: bold;
`;
export const StyledLinkButton = styled.a `
  color: #e6a50f;
  cursor: pointer;
  text-decoration: none;
`;
