import { requestListFeeds } from "./api/requestListFeeds";
import { requestPollFeed } from "./api/requestPollFeed";
export class NetworkFeedApi {
    async pollFeed(providerUrl, request) {
        return requestPollFeed(providerUrl, request);
    }
    async listFeeds(providerUrl) {
        return requestListFeeds(providerUrl);
    }
}
