import { groth16 } from "snarkjs";
import { loadVerificationKey } from "./artifacts";
import circuitParamJson from "./circuitParameters.json";
/**
 * Name identifier for the Proto-POD-GPC family of circuits.
 */
export const PROTO_POD_GPC_FAMILY_NAME = "proto-pod-gpc";
/**
 * Only the public input names, as run-time data.
 */
export const PROTO_POD_GPC_PUBLIC_INPUT_NAMES = [
    "entryObjectIndex",
    "entryNameHash",
    "entryIsValueEnabled",
    "entryIsValueHashRevealed",
    "virtualEntryIsValueHashRevealed",
    "entryEqualToOtherEntryByIndex",
    "ownerEntryIndex",
    "ownerExternalNullifier",
    "ownerIsNullfierHashRevealed",
    "tupleIndices",
    "listComparisonValueIndex",
    "listContainsComparisonValue",
    "listValidValues",
    "globalWatermark"
];
/**
 * ProtoPODGPCCircuitParams constructor.
 */
export function ProtoPODGPCCircuitParams(maxObjects, maxEntries, merkleMaxDepth, maxLists, maxListElements, maxTuples, tupleArity) {
    return {
        maxObjects,
        maxEntries,
        merkleMaxDepth,
        maxLists,
        maxListElements,
        maxTuples,
        tupleArity
    };
}
/**
 * Mapping taking a ProtoPODGPCCircuitParams to its array representation.
 * Inverse of {@link arrayToProtoPODGPCCircuitParam}.
 * This is necessary for invocations of the circuits themselves.
 */
export function protoPODGPCCircuitParamArray(params) {
    return [
        params.maxObjects,
        params.maxEntries,
        params.merkleMaxDepth,
        params.maxLists,
        params.maxListElements,
        params.maxTuples,
        params.tupleArity
    ];
}
/**
 * Mapping taking an array representation of parameters to
 * a ProtoPODGPCCircuitParams object.  Inverse of
 * {@link protoPODGPCCircuitParamArray}.
 */
export function arrayToProtoPODGPCCircuitParam(params) {
    return ProtoPODGPCCircuitParams(params[0], params[1], params[2], params[3], params[4], params[5], params[6]);
}
/**
 * Mapping computing the maximum number of virtual entries from given GPC
 * parameters.
 */
export function paramMaxVirtualEntries(params) {
    return params.maxObjects;
}
/**
 * Utility functions for the ProtoPODGPC family of circuits.
 *
 * TODO(POD-P3): Factor out and generalize if/when there are multiple
 * families and we're clear on what's common between them.
 */
export class ProtoPODGPC {
    /**
     * Generate a Groth16 proof for a circuit in this family.
     *
     * @param inputs full inputs (public and private)
     * @param wasmPath path to wasm file for witness generation.
     *   See {@link artifactPaths}.
     * @param pkeyPath path to file containing proving key.
     *   See {@link artifactPaths}.
     * @returns Groth16 proof, circuit outputs, and full set of public signals
     * (primarily for verification in tests).
     */
    static async prove(inputs, wasmPath, pkeyPath) {
        const { proof, publicSignals } = await groth16.fullProve(inputs, wasmPath, pkeyPath);
        const intPublicSignals = publicSignals.map(BigInt);
        const outputs = ProtoPODGPC.outputsFromPublicSignals(intPublicSignals, inputs.entryNameHash.length, inputs.objectSignatureS.length);
        return { proof, outputs, publicSignals: intPublicSignals };
    }
    /**
     * Verify a proof for a circuit in this library.
     *
     * @param vkeyPath path to verification key as a JSON file.
     *   See {@link artifactPaths}.
     * @param proof Groth16 proof.
     * @param publicInputs claimed public inputs to the circuit.
     *   See {@link filterPublicInputs}
     * @param outputs claimed outputs from the circuit (generally derived from
     *   claims).
     * @returns true if the proof is valid
     */
    static async verify(vkeyPath, proof, publicInputs, outputs) {
        const publicSignals = ProtoPODGPC.makePublicSignals(publicInputs, outputs);
        return await groth16.verify(await loadVerificationKey(vkeyPath), 
        // Snarkjs actually allows bigints (via call to stringifyBigInts in
        // ffjavascript), but @types/snarkjs doesn't know that.
        publicSignals, proof);
    }
    /**
     * Extract the public inputs from the full set of proof inputs.
     */
    static filterPublicInputs(allInputs) {
        return {
            entryObjectIndex: allInputs.entryObjectIndex,
            entryNameHash: allInputs.entryNameHash,
            entryIsValueEnabled: allInputs.entryIsValueEnabled,
            entryIsValueHashRevealed: allInputs.entryIsValueHashRevealed,
            virtualEntryIsValueHashRevealed: allInputs.virtualEntryIsValueHashRevealed,
            entryEqualToOtherEntryByIndex: allInputs.entryEqualToOtherEntryByIndex,
            ownerEntryIndex: allInputs.ownerEntryIndex,
            ownerExternalNullifier: allInputs.ownerExternalNullifier,
            ownerIsNullfierHashRevealed: allInputs.ownerIsNullfierHashRevealed,
            tupleIndices: allInputs.tupleIndices,
            listComparisonValueIndex: allInputs.listComparisonValueIndex,
            listContainsComparisonValue: allInputs.listContainsComparisonValue,
            listValidValues: allInputs.listValidValues,
            globalWatermark: allInputs.globalWatermark
        };
    }
    /**
     * Extract named outputs from the public circuit signals.
     *
     * Because of the flattened array representation of the public signals, the
     * circuit's maxEntries parameter must be known to properly reconstruct
     * output arrays.
     */
    static outputsFromPublicSignals(publicSignals, maxEntries, maxVirtualEntries) {
        return {
            entryRevealedValueHash: publicSignals.slice(0, maxEntries),
            virtualEntryRevealedValueHash: publicSignals.slice(maxEntries, maxEntries + maxVirtualEntries),
            ownerRevealedNullifierHash: publicSignals[maxEntries + maxVirtualEntries]
        };
    }
    /**
     * Creates a set of public signals for verification, given public inputs
     * and outputs of a circuit.
     */
    static makePublicSignals(inputs, outputs) {
        return [
            ...outputs.entryRevealedValueHash,
            ...outputs.virtualEntryRevealedValueHash,
            outputs.ownerRevealedNullifierHash,
            ...inputs.entryObjectIndex,
            ...inputs.entryNameHash,
            inputs.entryIsValueEnabled,
            inputs.entryIsValueHashRevealed,
            inputs.virtualEntryIsValueHashRevealed,
            ...inputs.entryEqualToOtherEntryByIndex,
            inputs.ownerEntryIndex,
            inputs.ownerExternalNullifier,
            inputs.ownerIsNullfierHashRevealed,
            ...inputs.tupleIndices.flat(),
            ...inputs.listComparisonValueIndex,
            inputs.listContainsComparisonValue,
            ...inputs.listValidValues.flat(),
            inputs.globalWatermark
        ].map(BigInt);
    }
    /**
     * Picks the smallest available circuit in this family which can handle the
     * size parameters of a desired configuration.
     *
     * @param params a lower bound on the parameters required
     * @returns the circuit description, or undefined if no circuit can handle
     *   the required parameters.
     */
    static pickCircuit(requiredParameters) {
        for (const circuitDesc of ProtoPODGPC.CIRCUIT_FAMILY) {
            if (ProtoPODGPC.circuitMeetsRequirements(circuitDesc, requiredParameters)) {
                return circuitDesc;
            }
        }
        return undefined;
    }
    /**
     * Finds the description of a circuit in this family by name.
     *
     * @param familyName the circuit family name
     * @param circuitName the name of the circuit
     * @returns the circuit description, or undefined if the name is
     *   unrecognized.
     */
    static findCircuit(familyName, circuitName) {
        if (familyName && familyName !== PROTO_POD_GPC_FAMILY_NAME) {
            return undefined;
        }
        for (const circuitDesc of ProtoPODGPC.CIRCUIT_FAMILY) {
            if (circuitName && circuitDesc.name === circuitName) {
                return circuitDesc;
            }
        }
        return undefined;
    }
    /**
     * Checks whether a described circuit can meet a required set of parameters.
     * This will be true if each of the circuit's parameters is greater than or
     * equal to the required value.
     *
     * @param circuitDesc description of the circuit to check
     * @param requiredParams the min required value of each circuit parameter
     * @returns `true` if the circuit meets the requirements.
     */
    static circuitMeetsRequirements(circuitDesc, requiredParams) {
        return (circuitDesc.maxObjects >= requiredParams.maxObjects &&
            circuitDesc.maxEntries >= requiredParams.maxEntries &&
            circuitDesc.merkleMaxDepth >= requiredParams.merkleMaxDepth &&
            circuitDesc.maxLists >= requiredParams.maxLists &&
            circuitDesc.maxListElements >= requiredParams.maxListElements &&
            circuitDesc.maxTuples >= requiredParams.maxTuples &&
            circuitDesc.tupleArity >= requiredParams.tupleArity);
    }
    /**
     * Calculates the merged set of parameters which meets the unified (maximum)
     * requirements of both inputs.
     *
     * @param rp1 first set of required parameters
     * @param rp2 second set of required paremeters
     * @returns unified (maximum) parameters
     */
    static mergeRequiredParams(rp1, rp2) {
        const array1 = protoPODGPCCircuitParamArray(rp1);
        const array2 = protoPODGPCCircuitParamArray(rp2);
        return arrayToProtoPODGPCCircuitParam(array1.map((p, i) => Math.max(p, array2[i])));
    }
    /**
     * Generates a circuit name based on parameters.
     */
    static circuitNameForParams(params) {
        return `${params.maxObjects}o-${params.maxEntries}e-${params.merkleMaxDepth}md-${params.maxLists}x${params.maxListElements}l-${params.maxTuples}x${params.tupleArity}t`;
    }
    static circuitDescForParams(circuitParams, cost) {
        return {
            family: PROTO_POD_GPC_FAMILY_NAME,
            name: ProtoPODGPC.circuitNameForParams(circuitParams),
            cost,
            ...circuitParams
        };
    }
}
/**
 * Circuit parameters pulled from `circuitParameters.json`
 * in the form of pairs consisting of the circuit parameters
 * and the cost of the circuit in constraints.
 */
ProtoPODGPC.CIRCUIT_PARAMETERS = circuitParamJson;
/**
 * List of pre-compiled circuits, sorted in order of increasing cost.
 * These should match the declarations in circuits.json for circomkit,
 * and each should correspond to an available set of precompiled artifacts.
 */
// TODO(POD-P2): Pick convenient circuit sizes for MVP.
ProtoPODGPC.CIRCUIT_FAMILY = ProtoPODGPC.CIRCUIT_PARAMETERS.sort((a, b) => a[1] - b[1]).map((pair) => ProtoPODGPC.circuitDescForParams(pair[0], pair[1]));
/**
 * Name of the package on NPM which contains published artifacts for this
 * GPC family.
 */
ProtoPODGPC.ARTIFACTS_NPM_PACKAGE_NAME = "@pcd/proto-pod-gpc-artifacts";
/**
 * Version of the published artifacts on NPM which are compatible with this
 * version of the GPC circuits.
 */
ProtoPODGPC.ARTIFACTS_NPM_VERSION = "0.2.0";
