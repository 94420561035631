[
    [
        {
            "maxObjects": 1,
            "maxEntries": 5,
            "merkleMaxDepth": 6,
            "maxLists": 0,
            "maxListElements": 0,
            "maxTuples": 0,
            "tupleArity": 0
        },
        13717
    ],
    [
        {
            "maxObjects": 3,
            "maxEntries": 10,
            "merkleMaxDepth": 8,
            "maxLists": 1,
            "maxListElements": 10,
            "maxTuples": 2,
            "tupleArity": 2
        },
        36977
    ],
    [
        {
            "maxObjects": 3,
            "maxEntries": 10,
            "merkleMaxDepth": 8,
            "maxLists": 2,
            "maxListElements": 20,
            "maxTuples": 1,
            "tupleArity": 4
        },
        36850
    ],
    [
        {
            "maxObjects": 3,
            "maxEntries": 10,
            "merkleMaxDepth": 8,
            "maxLists": 4,
            "maxListElements": 20,
            "maxTuples": 5,
            "tupleArity": 2
        },
        38085
    ]
]
