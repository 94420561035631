import urlJoin from "url-join";
import { httpGetSimple } from "./makeRequest";
/**
 * Asks the Zupass server whether or not it has feeds that can server
 * responses.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestIssuanceServiceEnabled(zupassServerUrl) {
    return httpGetSimple(urlJoin(zupassServerUrl, "/issue/enabled"), async (resText) => ({ value: resText === "true", success: true }));
}
