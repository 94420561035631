/**
 * Supported ticket groups for known tickets. This is based on pattern-matching
 * of event ID, product ID, and signing key.
 */
export var KnownTicketGroup;
(function (KnownTicketGroup) {
    KnownTicketGroup["Devconnect23"] = "Devconnect23";
    KnownTicketGroup["Zuzalu23"] = "Zuzalu23";
    KnownTicketGroup["Zuconnect23"] = "Zuconnect23";
    KnownTicketGroup["Other"] = "Other";
})(KnownTicketGroup || (KnownTicketGroup = {}));
export var PipelineLogLevel;
(function (PipelineLogLevel) {
    PipelineLogLevel["Info"] = "Info";
    PipelineLogLevel["Warning"] = "Warning";
    PipelineLogLevel["Error"] = "Error";
})(PipelineLogLevel || (PipelineLogLevel = {}));
/**
 * For known tickets, this is the type of the public key.
 * Possibly this information is redundant, but it seems useful to be
 * explicit about the type of key used.
 */
export var KnownPublicKeyType;
(function (KnownPublicKeyType) {
    KnownPublicKeyType["EdDSA"] = "eddsa";
    KnownPublicKeyType["RSA"] = "rsa";
})(KnownPublicKeyType || (KnownPublicKeyType = {}));
/**
 * The string the client must sign with the user's semaphore identity
 * in order to be able to request the PCDs that the server wants to
 * issue the user.
 */
export const ISSUANCE_STRING = "Issue me PCDs please.";
/**
 * New empty {@link OfflineTickets}.
 */
export function defaultOfflineTickets() {
    return {
        devconnectTickets: []
    };
}
export function isPerDayBadge(badge) {
    return badge.maxPerDay !== undefined;
}
