import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest";
/**
 * Asks the server to fetch the pipeline definition corresponding to the
 * given pipeline ID. Requires cookies, as this is part of generic issuance
 * user authentication.
 */
export async function requestGenericIssuanceDeletePipeline(zupassServerUrl, pipelineId, jwt) {
    return httpPostSimple(urlJoin(zupassServerUrl, `/generic-issuance/api/delete-pipeline/${pipelineId}`), async () => ({
        value: undefined,
        success: true
    }), {
        jwt
    });
}
