import { requireDefinedParameter } from "@pcd/util";
import { Identity } from "@semaphore-protocol/identity";
import JSONBig from "json-bigint";
import { v4 as uuid } from "uuid";
import { SemaphoreIdentityPCD, SemaphoreIdentityPCDTypeName } from "./SemaphoreIdentityPCD";
export async function prove(args) {
    return new SemaphoreIdentityPCD(uuid(), { identity: args.identity });
}
export async function verify(pcd) {
    return pcd?.claim?.identity !== undefined;
}
export async function serialize(pcd) {
    return {
        type: SemaphoreIdentityPCDTypeName,
        pcd: JSONBig.stringify({
            type: pcd.type,
            id: pcd.id,
            identity: pcd.claim.identity.toString()
        })
    };
}
export async function deserialize(serialized) {
    const { id, identity } = JSONBig.parse(serialized);
    requireDefinedParameter(id, "id");
    requireDefinedParameter(identity, "identity");
    return new SemaphoreIdentityPCD(id, {
        identity: new Identity(identity)
    });
}
export function getDisplayOptions(pcd) {
    return {
        header: "Semaphore Identity",
        displayName: "semaphore-id-" + pcd.claim.identity.commitment.toString().substring(0, 8)
    };
}
/**
 * PCD-conforming wrapper for the Semaphore zero-knowledge protocol. You can
 * find documentation of Semaphore here: https://semaphore.appliedzkp.org/docs/introduction
 */
export const SemaphoreIdentityPCDPackage = {
    name: SemaphoreIdentityPCDTypeName,
    getDisplayOptions,
    prove,
    verify,
    serialize,
    deserialize
};
