import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest";
/**
 * Once the user has verified their token, they can create a new
 * user on the backend using this function.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestCreateNewUser(zupassServerUrl, email, token, commitment, salt, encryptionKey, autoRegister) {
    return httpPostSimple(urlJoin(zupassServerUrl, "/account/new-participant"), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), {
        email,
        token,
        commitment,
        salt,
        encryptionKey,
        autoRegister
    });
}
