// Single source of truth for tuple prefix (used internally).
// This should not be a valid {@link PODName} to avoid name clashes.
export const TUPLE_PREFIX = "$tuple";
/**
 * Converts a record of membership lists to one of membership sets.
 *
 * @param membershipLists the lists to convert
 * @returns a record of membership sets
 */
export function membershipListsToSets(membershipLists) {
    return Object.fromEntries(Object.entries(membershipLists).map((pair) => [
        pair[0],
        new Set(pair[1])
    ]));
}
