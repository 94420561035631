import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { serializeGPCBoundConfig, serializeGPCRevealedClaims } from "@pcd/gpc";
import { GPCPCDPackage } from "@pcd/gpc-pcd";
import { FieldLabel, Separator, Spacer, styled } from "@pcd/passport-ui";
import { useState } from "react";
export const GPCPCDUI = {
    renderCardBody: GPCCardBody
};
function GPCCardBody({ pcd }) {
    const [verifyStatus, setVerifyStatus] = useState("unverified");
    return (_jsxs(Container, { children: [_jsx("p", { children: "This PCD represents ZK proof about one or more POD (Provable Object Data) objects, generated using a GPC (General Purpose Circuit). The proof configuration determines what specifically has been proven. This may include revealing some of the POD data, or keeping it all hidden." }), _jsx(Separator, {}), _jsx(FieldLabel, { children: "GPC Proof Config" }), _jsx("pre", { children: serializeGPCBoundConfig(pcd.claim.config, 2) }), _jsx(Spacer, { h: 8 }), _jsx(FieldLabel, { children: "Revealed Claims" }), _jsx("pre", { children: serializeGPCRevealedClaims(pcd.claim.revealed, 2) }), _jsx(Spacer, { h: 8 }), _jsxs("label", { children: [_jsx("button", { onClick: async () => setVerifyStatus((await GPCPCDPackage.verify(pcd)) ? "valid ✅" : "invalid ❌"), children: "Check" }), "Proof is ", verifyStatus] })] }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
