import urlJoin from "url-join";
import { httpGetSimple } from "./makeRequest";
/**
 * Asks the server for the password salt of a particular email address.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestPasswordSalt(zupassServerUrl, email) {
    return httpGetSimple(urlJoin(zupassServerUrl, `/account/salt?${new URLSearchParams({
        email
    }).toString()}`), async (resText) => ({
        value: resText,
        success: true
    }));
}
