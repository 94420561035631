import { jsx as _jsx } from "react/jsx-runtime";
import { Container } from "../shared";
export function CollectablePODPCDCardBody({ pcd }) {
    const parts = [];
    const imageUrlEntry = pcd.claim.entries["zupass_image_url"];
    if (imageUrlEntry?.type === "string") {
        parts.push(_jsx("img", { src: imageUrlEntry.value }));
    }
    const descriptionEntry = pcd.claim.entries["zupass_description"];
    if (descriptionEntry?.type === "string") {
        parts.push(_jsx("p", { children: descriptionEntry.value }));
    }
    if (parts.length === 0) {
        parts.push(_jsx("p", { children: "No content" }));
    }
    return _jsx(Container, { children: parts });
}
