import urlJoin from "url-join";
import { httpGet } from "./makeRequest";
/**
 * Asks the Zupass server for a particular user by their unique id.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestUser(zupassServerUrl, uuid) {
    return httpGet(urlJoin(zupassServerUrl, "/account/user", uuid), {
        onValue: async (resText) => ({
            value: JSON.parse(resText),
            success: true
        }),
        onError: async (resText, statusCode) => statusCode === 410
            ? { error: { userMissing: true }, success: false }
            : { error: { errorMessage: resText }, success: false }
    });
}
