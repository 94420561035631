import { numberToBigInt } from "@pcd/util";
import { Rarity } from "./EdDSAFrogPCD";
export function semaphoreIdToBigInt(v) {
    return BigInt(v);
}
/**
 * Converts the property values of the {@link IFrogData} object to
 * a list of big integers ({@link SerializedTicket}).
 */
export function frogDataToBigInts(data) {
    return [
        numberToBigInt(data.frogId),
        numberToBigInt(data.biome),
        numberToBigInt(data.rarity),
        numberToBigInt(data.temperament),
        numberToBigInt(data.jump),
        numberToBigInt(data.speed),
        numberToBigInt(data.intelligence),
        numberToBigInt(data.beauty),
        numberToBigInt(data.timestampSigned),
        semaphoreIdToBigInt(data.ownerSemaphoreId),
        numberToBigInt(0),
        numberToBigInt(0),
        numberToBigInt(0)
    ];
}
/**
 * Returns the data inside of this PCD if it exists.
 */
export function getEdDSAFrogData(pcd) {
    return pcd?.claim?.data;
}
/**
 * Returns the public key this PCD was signed with if it exists.
 */
export function getPublicKey(pcd) {
    return pcd?.proof?.eddsaPCD?.claim?.publicKey;
}
export function frogRarityToScore(frogRarity) {
    let rarityToEdgeCityScore = 1;
    switch (frogRarity) {
        case Rarity.Object:
        case Rarity.Unknown:
            rarityToEdgeCityScore *= 0;
            break;
        case Rarity.Common:
            rarityToEdgeCityScore *= 1;
            break;
        case Rarity.Rare:
            rarityToEdgeCityScore *= 4;
            break;
        case Rarity.Epic:
            rarityToEdgeCityScore *= 10;
            break;
        case Rarity.Legendary:
            rarityToEdgeCityScore *= 20;
            break;
        case Rarity.Mythic:
            rarityToEdgeCityScore *= 100;
            break;
    }
    return rarityToEdgeCityScore;
}
