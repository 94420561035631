import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest";
/**
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestOfflineTickets(passportServerUrl, postBody) {
    return httpPostSimple(urlJoin(passportServerUrl, "/issue/offline-tickets"), async (resText) => ({
        success: true,
        value: JSON.parse(resText)
    }), postBody);
}
