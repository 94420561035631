export const PSEUDONYM_TO_EMOJI = {
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴍᴏɴᴋᴇʏ": "🐒",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ʙᴇᴀʀ": "🐻",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴅᴜᴄᴋ": "🦆",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴏᴡʟ": "🦉",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ʜᴏʀꜱᴇ": "🐴",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ʙᴇᴇ": "🐝",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴡᴏʀᴍ": "🪱",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ʙᴜᴛᴛᴇʀꜰʟʏ": "🦋",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ꜱɴᴀɪʟ": "🐌",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴛᴜʀᴛʟᴇ": "🐢",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ʟɪᴢᴀʀᴅ": "🦎",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ꜰʀᴏɢ": "🐸",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴛ ʀᴇx": "🦖",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴏᴄᴛᴏᴘᴜꜱ": "🐙",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ꜱʜʀɪᴍᴘ": "🦐",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴄʀᴀʙ": "🦀",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ꜰɪꜱʜ": "🐟",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴅᴏʟᴘʜɪɴ": "🐬",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴡʜᴀʟᴇ": "🐳",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴢᴇʙʀᴀ": "🦓",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴏʀᴀɴɢᴜᴛᴀɴ": "🦧",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴇʟᴇᴘʜᴀɴᴛ": "🐘",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴄᴀᴍᴇʟ": "🐫",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ɢɪʀᴀꜰꜰᴇ": "🦒",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴋᴀɴɢᴀʀᴏᴏ": "🦘",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴄᴏᴡ": "🐄",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ꜱʜᴇᴇᴘ": "🐑",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴄᴀᴛ": "🐈",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴄʜɪᴄᴋᴇɴ": "🐓",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴘᴀʀʀᴏᴛ": "🦜",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ꜰʟᴀᴍɪɴɢᴏ": "🦩",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ꜱᴋᴜɴᴋ": "🦨",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ʀᴀᴄᴄᴏᴏɴ": "🦝",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ꜱʟᴏᴛʜ": "🦥",
    "ᴀɴᴏɴ ᴇ. ᴍᴏᴜꜱᴇ": "🐀",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ʜᴇᴅɢᴇʜᴏɢ": "🦔",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ʟᴏɢ": "🪵",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴍᴜꜱʜʀᴏᴏᴍ": "🍄",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴄᴀᴄᴛᴜꜱ": "🌵",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ꜱᴜɴꜰʟᴏᴡᴇʀ": "🌻",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴄᴏʀɴ": "🌽",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴘᴇᴀɴᴜᴛ": "🥜",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ʙᴀɢᴜᴇᴛᴛᴇ": "🥖",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴄᴀɴᴏᴇ": "🛶",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ʏᴀᴍ": "🍠",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴘᴜᴍᴘᴋɪɴ": "🎃",
    "ᴀɴᴏɴʏᴍᴏᴜꜱ ᴍᴏᴏɴ": "🌚"
};
export function bigIntToPseudonymEmoji(input) {
    const key = Number(input % BigInt(Object.keys(PSEUDONYM_TO_EMOJI).length));
    return Object.values(PSEUDONYM_TO_EMOJI)[key];
}
export function bigIntToPseudonymName(input) {
    const key = Number(input % BigInt(Object.keys(PSEUDONYM_TO_EMOJI).length));
    return Object.keys(PSEUDONYM_TO_EMOJI)[key];
}
/** Converts a given number, such as a nullifier hash, to a pseudonym. */
export function bigintToPseudonym(input) {
    return `${bigIntToPseudonymEmoji(input)} ${bigIntToPseudonymName(input)}`;
}
