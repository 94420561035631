import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest";
/**
 * Prior to performing an action such as check-in on a ticket, retrieve a list
 * of supported actions, if any. If the credential does not authorize the
 * performance of actions on this ticket, the return value will indicate this.
 *
 * {@link credential} is a Semaphore Signature of a payload that is a
 * `JSON.stringify`-ed {@link CredentialPayload}.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestPodboxTicketActionPreCheck(preCheckUrl, credential, action, ticketId, eventId) {
    return httpPostSimple(urlJoin(preCheckUrl), async (resText) => {
        return {
            success: true,
            value: JSON.parse(resText)
        };
    }, {
        credential,
        action,
        ticketId,
        eventId
    });
}
