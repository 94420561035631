import { useEffect, useState } from "react";
import { requestUser } from "./api/requestUser";
export function useFetchUser(zupassServerUrl, isZuzalu, uuid) {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const doLoad = async () => {
            if (uuid === undefined) {
                setUser(null);
                setError(null);
                setLoading(false);
                return;
            }
            setLoading(true);
            const userResult = await requestUser(zupassServerUrl, uuid);
            setLoading(false);
            if (userResult.success) {
                setUser(userResult.value);
            }
            else {
                if (userResult.error.userMissing) {
                    setError(`no user with id '${uuid}'`);
                }
                else {
                    setError(userResult.error.errorMessage);
                }
            }
        };
        doLoad();
    }, [isZuzalu, zupassServerUrl, uuid]);
    return { user, error, loading };
}
