import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import styled from "./StyledWrapper";
export function HiddenText({ text, style }) {
    const [visible, setVisible] = useState(false);
    const onRevealClick = useCallback(() => {
        setVisible(true);
    }, []);
    if (visible) {
        return _jsx(TextContainer, { style: style, children: text });
    }
    return (_jsx(HiddenTextContainer, { onClick: onRevealClick, children: "tap to reveal" }));
}
export const TextContainer = styled.div `
  border: 2px solid var(--primary-lite);
  overflow: hidden;
  padding: 4px 8px;
  border-radius: 4px;
  margin-bottom: 8px;
`;
export const HiddenTextContainer = styled.div `
  border: 2px solid var(--primary-lite);
  overflow: hidden;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
`;
