import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest";
/**
 * Asks the server to get information about the currently logged in user.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestGenericIssuanceSelf(zupassServerUrl, jwt) {
    return httpPostSimple(urlJoin(zupassServerUrl, "/generic-issuance/api/self"), async (resText) => {
        return {
            success: true,
            value: JSON.parse(resText)
        };
    }, {
        jwt
    });
}
