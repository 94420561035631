import urlJoin from "url-join";
import { httpGetSimple } from "./makeRequest";
/**
 * Asks the Zupass server about the status of particular pending PCD proof.
 *
 * Never rejects. All information encoded in the resolved response.
 *
 * @todo - deprecate this
 */
export async function requestServerProofStatus(zupassServerUrl, proveRequest) {
    return httpGetSimple(urlJoin(zupassServerUrl, `/pcds/status`), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), proveRequest);
}
