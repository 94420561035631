import urlJoin from "url-join";
import { httpPost } from "./makeRequest";
/**
 * For Devconnect tickets, pre-check a ticket before attempting check-in.
 *
 * Sends only the ticket ID. See {@link requestCheckTicket} for an
 * alternative API which sends a serialized PCD.
 *
 * Does NOT check in the user, rather checks whether the ticket is valid and
 * can be used to check in.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestCheckTicketById(passportServerUrl, postBody) {
    return httpPost(urlJoin(passportServerUrl, "/issue/check-ticket-by-id"), {
        onValue: async (resText) => JSON.parse(resText),
        onError: async () => ({
            error: { name: "ServerError" },
            success: false
        })
    }, postBody);
}
