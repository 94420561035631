import urlJoin from "url-join";
import { onNamedAPIError } from "./apiResult";
import { httpGet } from "./makeRequest";
/**
 * Downloads, but does not decrypt, a user's end-to-end encrypted backup
 * of their PCDs and other user data. The server never learns the user's
 * encryption key.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestEncryptedStorage(zupassServerUrl, blobKey, knownRevision) {
    return httpGet(urlJoin(zupassServerUrl, "/sync/v3/load"), {
        onValue: async (resText) => ({
            value: JSON.parse(resText),
            success: true
        }),
        onError: onNamedAPIError
    }, { blobKey, knownRevision });
}
