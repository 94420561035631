import { Emitter } from "@pcd/emitter";
import { ObjPCD, ObjPCDPackage } from "@pcd/obj-pcd";
import { getHash } from "@pcd/passport-crypto";
import { matchActionToPermission } from "@pcd/pcd-collection";
import { isFulfilled, randomUUID } from "@pcd/util";
import stringify from "fast-json-stable-stringify";
import { v4 as uuid } from "uuid";
export var ZupassFeedIds;
(function (ZupassFeedIds) {
    ZupassFeedIds["Devconnect"] = "1";
    ZupassFeedIds["Frogs"] = "2";
    ZupassFeedIds["Email"] = "3";
    ZupassFeedIds["Zuzalu_23"] = "4";
    ZupassFeedIds["Zuconnect_23"] = "5";
})(ZupassFeedIds || (ZupassFeedIds = {}));
/**
 * Applies a set of actions to a PCD collection.
 */
export async function applyActions(collection, actions) {
    for (const actionSet of actions) {
        for (const action of actionSet.actions) {
            // tryExec already handles any exceptions that can come from executing
            // actions, so we don't need to catch anything here.
            await collection.tryExec(action, actionSet.subscription.feed.permissions);
        }
    }
}
function ensureHasId(sub) {
    if (!sub.id) {
        sub.id = uuid();
    }
    return sub;
}
/**
 * Class responsible for storing the list of feed providers this application is
 * aware of, as well as the list of feeds that each provider can serve, and which
 * of those we are subscribed to.
 */
export class FeedSubscriptionManager {
    constructor(api, providers, activeSubscriptions, errors) {
        this.updatedEmitter = new Emitter();
        this.api = api;
        this.providers = providers ?? [];
        this.activeSubscriptions = (activeSubscriptions ?? []).map(ensureHasId);
        this.errors = errors !== undefined ? new Map(errors) : new Map();
    }
    /**
     * Creates a new FeedSubscriptionManager with the data from this one.
     *
     * This includes all data (subscriptions, providers, and errors, but doesn't
     * include dynamic state (listeners on the emitter).
     *
     * This is a shallow clone.  The resulting object has new containers but
     * the same underlying objects, which are expected to be immutable.  If you
     * need a deep clone, use serialization.
     */
    clone() {
        return new FeedSubscriptionManager(this.api, [...this.providers], [...this.activeSubscriptions], this.errors);
    }
    /**
     * Merges subscriptions from `other` into `this`, returning an object with
     * a count of the number of new providers and subscriptions added.
     *
     * Only subscriptions and providers are included in the merge.  Other
     * non-persistent state (listeners, errors) is ignored.
     *
     * The merge only ever adds new providers and/or subscriptions which do not
     * already exist.  Existing entries are never mutated.  Subscriptions will be
     * added only if their subscription ID is globally unique, and their feed ID
     * is unique within the scope of their provider URL.  Providers are added
     * only if there is a new subscription to add and no existing provider
     * for its URL.
     */
    merge(other) {
        // Prepare a set of IDs of the feeds ands subs we have, for quick lookup.
        const haveSubIDs = new Set();
        const haveFeeds = new Set();
        for (const sub of this.activeSubscriptions) {
            haveSubIDs.add(sub.id);
            haveFeeds.add(sub.providerUrl + "|" + sub.feed.id);
        }
        let newProviders = 0;
        let newSubscriptions = 0;
        for (const [providerUrl, subs] of other
            .getSubscriptionsByProvider()
            .entries()) {
            // Copy provider if not already known.
            const ensureProvider = () => {
                if (!this.hasProvider(providerUrl)) {
                    const otherProvider = other.getProvider(providerUrl);
                    if (otherProvider === undefined)
                        return; // other's state is illegal?
                    this.addProvider(providerUrl, otherProvider.providerName, otherProvider.timestampAdded);
                    newProviders++;
                }
            };
            // Copy each subscription if it doesn't already exist.  We eliminate
            // duplicates of either the same subscription ID or feed ID.
            for (const sub of subs) {
                const feedString = sub.providerUrl + "|" + sub.feed.id;
                if (!haveSubIDs.has(sub.id) && !haveFeeds.has(feedString)) {
                    ensureProvider();
                    this.activeSubscriptions.push({ ...sub });
                    haveSubIDs.add(sub.id);
                    haveFeeds.add(feedString);
                    newSubscriptions++;
                }
            }
        }
        this.updatedEmitter.emit();
        return { newProviders, newSubscriptions };
    }
    /**
     * Fetches a list of all feeds from the given provider URL.
     */
    async listFeeds(providerUrl) {
        return this.api.listFeeds(providerUrl).then((r) => {
            if (r.success) {
                return r.value;
            }
            else {
                throw new Error(r.error);
            }
        });
    }
    /**
     * This "refreshes" a feed. Existing feed errors are cleared, and new
     * ones may be detected.
     *
     * Returns the successful responses. Failures will be recorded in
     * `this.errors` for display to the user.
     */
    async pollSubscriptions(credentialManager, onFinish) {
        const responsePromises = [];
        for (const subscription of this.activeSubscriptions) {
            // Subscriptions which have ceased issuance should not be polled
            if (subscription.ended) {
                continue;
            }
            // nb: undefined autoPoll defaults to true
            if (subscription.feed.autoPoll === false) {
                continue;
            }
            responsePromises.push(this.fetchSingleSubscription(subscription, credentialManager, onFinish));
        }
        const responses = (await Promise.allSettled(responsePromises))
            .filter((isFulfilled))
            .flatMap((result) => result.value);
        this.updatedEmitter.emit();
        return responses;
    }
    /**
     * Poll a single subscription. Intended for use when resolving errors
     * with a feed that failed to load due to network/connection issues.
     */
    async pollSingleSubscription(subscription, credentialManager, onFinish) {
        const actions = await this.fetchSingleSubscription(subscription, credentialManager, onFinish);
        this.updatedEmitter.emit();
        return actions;
    }
    static saveAuthKey(authKey) {
        if (authKey === undefined) {
            localStorage?.removeItem(this.AUTH_KEY_KEY);
        }
        else {
            localStorage?.setItem(this.AUTH_KEY_KEY, authKey);
        }
    }
    getSavedAuthKey() {
        return (localStorage?.getItem(FeedSubscriptionManager.AUTH_KEY_KEY) ?? undefined);
    }
    async getAuthKeyForFeed(sub) {
        const podboxServerUrl = process.env.PASSPORT_SERVER_URL;
        if (!podboxServerUrl) {
            return undefined;
        }
        if (!sub.providerUrl.startsWith(podboxServerUrl)) {
            return undefined;
        }
        return this.getSavedAuthKey();
    }
    async makeAlternateCredentialPCD(authKey) {
        return await ObjPCDPackage.serialize(new ObjPCD(randomUUID(), {}, { obj: { authKey } }));
    }
    /**
     * Performs the network fetch of a subscription, and inspects the results
     * for validity. The error log for the subscription will be reset and
     * repopulated, so callers should check this in order to determine success.
     */
    async fetchSingleSubscription(subscription, credentialManager, onFinish) {
        const responses = [];
        this.resetError(subscription.id);
        try {
            const authKey = await this.getAuthKeyForFeed(subscription);
            const pcdCredential = authKey
                ? await this.makeAlternateCredentialPCD(authKey)
                : await credentialManager.requestCredential({
                    signatureType: "sempahore-signature-pcd",
                    pcdType: subscription.feed.credentialRequest.pcdType
                });
            const result = await this.api.pollFeed(subscription.providerUrl, {
                feedId: subscription.feed.id,
                pcd: pcdCredential
            });
            if (!result.success) {
                if (result.code === 410) {
                    this.flagSubscriptionAsEnded(subscription.id, result.error);
                    return responses;
                }
                throw new Error(result.error);
            }
            const { actions } = result.value;
            this.validateActions(subscription, actions);
            const subscriptionActions = { actions, subscription };
            if (onFinish) {
                await onFinish(subscriptionActions);
                this.updatedEmitter.emit();
            }
            responses.push(subscriptionActions);
        }
        catch (e) {
            this.setError(subscription.id, {
                type: SubscriptionErrorType.FetchError,
                e: e instanceof Error ? e : undefined
            });
        }
        return responses;
    }
    /**
     * Validates that the actions received in a feed are permitted by the user.
     */
    validateActions(subscription, actions) {
        const grantedPermissions = subscription.feed.permissions;
        const failedActions = [];
        for (const action of actions) {
            if (!matchActionToPermission(action, grantedPermissions)) {
                failedActions.push(action);
            }
        }
        if (failedActions.length > 0) {
            console.log(subscription);
            this.setError(subscription.id, {
                type: SubscriptionErrorType.PermissionError,
                actions: failedActions
            });
        }
    }
    getSubscriptionsByProvider() {
        const result = new Map();
        const providers = this.getProviders();
        for (const provider of providers) {
            const array = result.get(provider.providerUrl) ?? [];
            result.set(provider.providerUrl, array);
            array.push(...this.activeSubscriptions.filter((s) => s.providerUrl === provider.providerUrl));
        }
        return result;
    }
    unsubscribe(subscriptionId) {
        const existingSubscription = this.getSubscription(subscriptionId);
        if (!existingSubscription) {
            throw new Error(`no subscription with id ${subscriptionId}`);
        }
        this.activeSubscriptions = this.activeSubscriptions.filter((s) => s.id !== subscriptionId);
        this.errors.delete(existingSubscription.id);
        const remainingSubscriptionsOnProvider = this.getSubscriptionsForProvider(existingSubscription.providerUrl);
        if (remainingSubscriptionsOnProvider.length === 0) {
            this.removeProvider(existingSubscription.providerUrl);
        }
        this.updatedEmitter.emit();
    }
    removeProvider(providerUrl) {
        const subscriptions = this.getSubscriptionsForProvider(providerUrl);
        if (subscriptions.length > 0) {
            throw new Error(`can't remove provider ${providerUrl} - have ${subscriptions.length} existing subscriptions`);
        }
        this.providers = this.providers.filter((p) => p.providerUrl !== providerUrl);
        this.updatedEmitter.emit();
    }
    getSubscriptionsForProvider(providerUrl) {
        return this.activeSubscriptions.filter((s) => s.providerUrl === providerUrl);
    }
    findSubscription(providerUrl, feedId) {
        return this.activeSubscriptions.find((sub) => {
            return sub.providerUrl === providerUrl && sub.feed.id === feedId;
        });
    }
    async subscribe(providerUrl, info, replace) {
        if (!this.hasProvider(providerUrl)) {
            throw new Error(`provider ${providerUrl} does not exist`);
        }
        // This check will be wrong if we want to support multiple subscriptions
        // to the same feed with different credentials (e.g. multiple email
        // PCDs). For now the UI does not allow multiple subs to the same feed.
        const providerSubs = this.getSubscriptionsByProvider().get(providerUrl);
        const existingSubscription = providerSubs && providerSubs.find((sub) => sub.feed.id === info.id);
        if (existingSubscription && !replace) {
            throw new Error(`already subscribed on provider ${providerUrl} to feed ${info.id} `);
        }
        if (info.credentialRequest.pcdType &&
            info.credentialRequest.pcdType !== "email-pcd") {
            throw new Error(`non-supported credential PCD requested on ${providerUrl} feed ${info.id}`);
        }
        let sub;
        if (existingSubscription) {
            sub = existingSubscription;
            sub.feed = { ...info };
            sub.providerUrl = providerUrl;
        }
        else {
            sub = {
                id: uuid(),
                feed: { ...info },
                providerUrl: providerUrl,
                subscribedTimestamp: Date.now(),
                ended: false
            };
            this.activeSubscriptions.push(sub);
        }
        this.updatedEmitter.emit();
        return sub;
    }
    updateFeedPermissionsForSubscription(subscriptionId, permissions) {
        const sub = this.getSubscription(subscriptionId);
        if (!sub) {
            throw new Error(`no subscription found matching ${subscriptionId}`);
        }
        sub.feed.permissions = permissions;
        this.updatedEmitter.emit();
    }
    flagSubscriptionAsEnded(subscriptionId, message) {
        const sub = this.getSubscription(subscriptionId);
        if (!sub) {
            throw new Error(`no subscription found matching ${subscriptionId}`);
        }
        sub.ended = true;
        sub.ended_message = message;
        this.updatedEmitter.emit();
    }
    getSubscription(subscriptionId) {
        return this.activeSubscriptions.find((s) => s.id === subscriptionId);
    }
    getSubscriptionsByProviderAndFeedId(providerUrl, feedId) {
        return this.activeSubscriptions.filter((s) => s.feed.id === feedId && s.providerUrl === providerUrl);
    }
    hasProvider(providerUrl) {
        return this.getProvider(providerUrl) !== undefined;
    }
    getProvider(providerUrl) {
        return this.providers.find((p) => p.providerUrl === providerUrl);
    }
    getOrAddProvider(providerUrl, providerName, timestampAdded) {
        const existingProvider = this.getProvider(providerUrl);
        if (existingProvider) {
            return existingProvider;
        }
        return this.addProvider(providerUrl, providerName, timestampAdded);
    }
    addProvider(providerUrl, providerName, timestampAdded) {
        if (this.hasProvider(providerUrl)) {
            throw new Error(`provider ${providerUrl} already exists`);
        }
        const newProvider = {
            providerUrl,
            providerName,
            timestampAdded: timestampAdded ?? Date.now()
        };
        this.providers.push(newProvider);
        this.updatedEmitter.emit();
        return newProvider;
    }
    getProviders() {
        return this.providers;
    }
    getActiveSubscriptions() {
        return this.activeSubscriptions;
    }
    serialize() {
        return stringify({
            providers: this.providers,
            subscribedFeeds: this.activeSubscriptions,
            _storage_version: "v1"
        });
    }
    /**
     * Create a FeedSubscriptionManager from serialized data.
     * Upgrades from serialized data based on version number.
     */
    static deserialize(api, serialized) {
        const parsed = JSON.parse(serialized);
        if (parsed._storage_version === undefined) {
            const providers = parsed.providers ?? [];
            const subscribedFeeds = (parsed.subscribedFeeds ?? []).map((sub) => {
                const feed = {
                    id: sub.feed.id,
                    name: sub.feed.name,
                    description: sub.feed.description,
                    permissions: sub.feed.permissions,
                    credentialRequest: {
                        signatureType: "sempahore-signature-pcd",
                        ...(sub.feed.credentialType === "email-pcd"
                            ? { pcdType: sub.feed.credentialType }
                            : {})
                    }
                };
                return {
                    id: sub.id,
                    feed,
                    providerUrl: sub.providerUrl,
                    subscribedTimestamp: sub.subscribedTimestamp,
                    ended: sub.ended ?? false
                };
            });
            return new FeedSubscriptionManager(api, providers, subscribedFeeds);
        }
        return new FeedSubscriptionManager(api, parsed.providers ?? [], parsed.subscribedFeeds ?? []);
    }
    setError(subscriptionId, error) {
        console.log({ subscriptionId, error });
        this.errors.set(subscriptionId, error);
    }
    resetError(subscriptionId) {
        this.errors.delete(subscriptionId);
    }
    getError(subscriptionId) {
        return this.errors.get(subscriptionId) ?? null;
    }
    getAllErrors() {
        return this.errors;
    }
    async getHash() {
        return await getHash(this.serialize());
    }
}
FeedSubscriptionManager.AUTH_KEY_KEY = "authKey";
export var SubscriptionErrorType;
(function (SubscriptionErrorType) {
    // The feed contained actions which the user has not permitted
    SubscriptionErrorType["PermissionError"] = "permission-error";
    // The feed could not be fetched
    SubscriptionErrorType["FetchError"] = "fetch-error";
})(SubscriptionErrorType || (SubscriptionErrorType = {}));
