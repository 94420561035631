import { getErrorMessage, sleep } from "@pcd/util";
import urlJoin from "url-join";
import { POST } from "./constants";
/**
 * Wrapper of {@link httpRequest} that sends a GET request.
 */
export async function httpGet(url, opts, query, includeCredentials = false) {
    return httpRequest(urlJoin(url, "?" + new URLSearchParams(query ?? {})), opts, includeCredentials);
}
/**
 * Wrapper of {@link httpRequest} that sends a POST request.
 */
export async function httpPost(url, opts, postBody, includeCredentials = false) {
    return httpRequest(url, opts, includeCredentials, "POST", postBody);
}
/**
 * Wrapper of {@link httpRequest} that sends a PUT request.
 */
export async function httpPut(url, opts, putBody, includeCredentials = false) {
    return httpRequest(url, opts, includeCredentials, "PUT", putBody);
}
/**
 * Wrapper of {@link httpRequest} that sends a DE:ETE request.
 */
export async function httpDelete(url, opts, includeCredentials = false) {
    return httpRequest(urlJoin(url), opts, includeCredentials, "DELETE");
}
/**
 * Shorthand for a {@link httpGet} whose error type is a string.
 */
export async function httpGetSimple(url, onValue, query, includeCredentials = false) {
    return httpGet(url, {
        onValue,
        onError: async (resText, code) => ({
            error: resText,
            success: false,
            code
        })
    }, query, includeCredentials);
}
/**
 * Shorthand for a {@link httpPost} whose error type is a string.
 */
export async function httpPostSimple(url, onValue, postBody, includeCredentials = false) {
    return httpPost(url, {
        onValue,
        onError: async (resText, code) => ({
            error: resText,
            success: false,
            code
        })
    }, postBody, includeCredentials);
}
/**
 * Shorthand for a {@link httpPut} whose error type is a string.
 */
export async function httpPutSimple(url, onValue, putBody, includeCredentials = false) {
    return httpPut(url, {
        onValue,
        onError: async (resText, code) => ({
            error: resText,
            success: false,
            code
        })
    }, putBody, includeCredentials);
}
/**
 * Shorthand for a {@link httpDelete} whose error type is a string.
 */
export async function httpDeleteSimple(url, onValue, includeCredentials = false) {
    return httpDelete(url, {
        onValue,
        onError: async (resText, code) => ({
            error: resText,
            success: false,
            code
        })
    }, includeCredentials);
}
/**
 * DEVELOPMENT ONLY!
 *
 * Set this to a value like 5000 to delay all http requests by 5 seconds.
 * Useful for testing intermediate loading states in the Zupass application.
 */
const throttleMs = 0;
/**
 * Sends a non-blocking HTTP request to the given URL, either a POST
 * or a GET, with the given body, and converts it into a {@link APIResult}.
 *
 * Never rejects.
 */
async function httpRequest(url, opts, includeCredentials, method, requestBody) {
    await sleep(throttleMs);
    let requestOptions = {
        method: "GET"
    };
    if (includeCredentials) {
        requestOptions = {
            ...requestOptions,
            credentials: "include"
        };
    }
    if (requestBody) {
        requestOptions = {
            ...requestOptions,
            ...POST,
            body: JSON.stringify(requestBody)
        };
    }
    if (method) {
        requestOptions = { ...requestOptions, method };
    }
    try {
        const res = await fetch(url, requestOptions);
        const resText = await res.text();
        if (!res.ok) {
            return await opts.onError(resText, res.status);
        }
        return await opts.onValue(resText);
    }
    catch (e) {
        // eslint-disable-next-line no-useless-catch
        try {
            return await opts.onError(getErrorMessage(e), undefined);
        }
        catch (e) {
            throw e;
        }
    }
}
export async function mapStringError(resText) {
    return { error: resText, success: false };
}
