import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ErrorContainer, Separator } from "@pcd/passport-ui";
import { PODPCDPackage } from "@pcd/pod-pcd";
import { getErrorMessage } from "@pcd/util";
import { useState } from "react";
import { CollectablePODPCDCardBody } from "./renderers/CollectablePODPCDCardBody";
import { DefaultPODPCDCardBody } from "./renderers/DefaultPODPCDCardBody";
import { Container } from "./shared";
export const PODPCDUI = {
    renderCardBody: PODPCDCardBody
};
var PODDisplayFormat;
(function (PODDisplayFormat) {
    PODDisplayFormat["POD"] = "pod";
    PODDisplayFormat["Collectable"] = "collectable";
})(PODDisplayFormat || (PODDisplayFormat = {}));
/**
 * This component renders the body of a 'Card' that Zupass uses to display PCDs to the user.
 */
function PODPCDCardBody({ pcd }) {
    const [sigStatus, setSigStatus] = useState(0);
    const [error, setError] = useState();
    const availableDisplayFormat = getPreferredDisplayFormat(pcd);
    const [displayFormat, setDisplayFormat] = useState(availableDisplayFormat || PODDisplayFormat.POD);
    const otherDisplayFormat = displayFormat === PODDisplayFormat.POD
        ? availableDisplayFormat
        : PODDisplayFormat.POD;
    let content = _jsx(_Fragment, {});
    switch (displayFormat) {
        case PODDisplayFormat.Collectable:
            content = _jsx(CollectablePODPCDCardBody, { pcd: pcd });
            break;
        case PODDisplayFormat.POD:
        // Fallthrough
        default:
            content = _jsx(DefaultPODPCDCardBody, { pcd: pcd });
            break;
    }
    const sigButtonColor = {};
    if (sigStatus > 0) {
        sigButtonColor.color = "white";
        sigButtonColor.background = "green";
    }
    else if (sigStatus < 0) {
        sigButtonColor.color = "white";
        sigButtonColor.background = "var(--danger)";
    }
    return (_jsxs(Container, { children: [content, _jsx(Separator, {}), otherDisplayFormat === undefined ? null : (_jsxs(Button, { style: "secondary", size: "small", onClick: async () => setDisplayFormat(otherDisplayFormat || "pod"), styles: { float: "left" }, children: ["View as ", getFormatDisplayName(otherDisplayFormat)] })), _jsx(Button, { style: "primary", size: "small", onClick: async () => {
                    setError(undefined);
                    const sigResult = await verifySignature(pcd);
                    setError(sigResult.errorMessage);
                    setSigStatus(sigResult.isValid ? 1 : -1);
                }, styles: { float: "right", ...sigButtonColor }, children: sigStatus === 0
                    ? "Check signature"
                    : sigStatus > 0
                        ? "Valid signature"
                        : error !== undefined
                            ? "Signature error!"
                            : "Bad signature!" }), error === undefined ? null : _jsx(ErrorContainer, { children: error })] }));
}
function getFormatDisplayName(displayFormat) {
    switch (displayFormat) {
        case PODDisplayFormat.POD:
            return "POD";
        case PODDisplayFormat.Collectable:
            return "Card";
    }
}
function getPreferredDisplayFormat(podpcd) {
    const displayEntry = podpcd.claim.entries["zupass_display"]?.value;
    if (displayEntry !== undefined &&
        typeof displayEntry === "string" &&
        displayEntry !== PODDisplayFormat.POD) {
        if (Object.values(PODDisplayFormat).indexOf(displayEntry) !== -1) {
            return displayEntry;
        }
    }
    return undefined;
}
async function verifySignature(pcd) {
    try {
        const isValid = await PODPCDPackage.verify(pcd);
        return { isValid };
    }
    catch (e) {
        return { isValid: false, errorMessage: getErrorMessage(e) };
    }
}
