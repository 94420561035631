import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest";
/**
 * Get data for all the frogs. Optionally upload new frogs.
 *
 * This endpoint is only available to authenticated admin users.
 */
export async function requestFrogCryptoDeleteFrogs(zupassServerUrl, req) {
    return httpPostSimple(urlJoin(zupassServerUrl, "/frogcrypto/admin/delete-frogs"), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), req);
}
