import urljoin from "url-join";
import { httpGetSimple } from "./makeRequest";
/**
 * Retrieves a summary of check-in status for all tickets on a given pipeline.
 * See {@link PipelineGetManualCheckInsResponseValue}.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestGenericIssuanceGetManualCheckIns(zupassServerUrl, pipelineId, key) {
    return httpGetSimple(urljoin(zupassServerUrl, "/generic-issuance/api/manual-checkin", pipelineId, key), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }));
}
