import { httpPostSimple } from "./makeRequest";
/**
 * Asks a feed for new PCDs.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestPollFeed(url, req) {
    return httpPostSimple(url, async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), req);
}
