import { r as BabyJubjubR } from "@zk-kit/baby-jubjub";
/**
 * Regex matching legal names for POD entries.  This is intended to make
 * entry names usable as identifiers in TypeScript and other languages.
 */
export const POD_NAME_REGEX = new RegExp(/^[A-Za-z_]\w*$/);
/**
 * Identifier for EdDSA public key string type.
 */
export const EDDSA_PUBKEY_TYPE_STRING = "eddsa_pubkey";
/**
 * Regex matching legal values for types encoded as strings by
 * {@link podValueToRawValue}. This matches strings of the form
 * `pod_${PODName}:${string}`.
 */
export const POD_STRING_TYPE_REGEX = new RegExp(/pod_([A-Za-z_]\w*):(.*)$/);
/**
 * Minimum legal value of a `cryptographic` entry value.
 */
export const POD_CRYPTOGRAPHIC_MIN = 0n;
/**
 * Maximum legal value of a `cryptographic` entry value.
 */
export const POD_CRYPTOGRAPHIC_MAX = BabyJubjubR - 1n;
// TODO(POD-P3): Decide on default int bounds and sign for POD.
// These values limit ints to 63 bits unsigned, to leave room if we
// decide on 64-bit signed later.
/**
 * Minimum legal value of an `int` entry value.
 */
export const POD_INT_MIN = 0n;
/**
 * Maximum legal value of an `int` entry value.
 */
export const POD_INT_MAX = (1n << 63n) - 1n;
