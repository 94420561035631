import urlJoin from "url-join";
import { onNamedAPIError } from "./apiResult";
import { httpPost } from "./makeRequest";
/**
 * Asks to upload an e2ee encrypted blob to the Zupass server. The server
 * never learns the user's encryption key.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestUploadEncryptedStorage(zupassServerUrl, blobKey, encryptedStorage, knownRevision) {
    return httpPost(urlJoin(zupassServerUrl, `/sync/v3/save`), {
        onValue: async (resText) => ({
            value: JSON.parse(resText),
            success: true
        }),
        onError: onNamedAPIError
    }, {
        blobKey,
        encryptedBlob: JSON.stringify(encryptedStorage),
        knownRevision
    });
}
