import urlJoin from "url-join";
import { httpGetSimple } from "./makeRequest";
/**
 * Asks the Zupass server for its EdDSA public key, which can be
 * used to verify Zupass-issued attestations.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestServerEdDSAPublicKey(zupassServerUrl) {
    return httpGetSimple(urlJoin(zupassServerUrl, `/issue/eddsa-public-key`), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }));
}
