import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest";
/**
 * Similar to {@link requestVerifyTicket}, but instead of taking a whole PCD,
 * instead takes a ticket ID and a timestamp, and uses server-side lookup to
 * provide information about the PCD.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestVerifyTicketById(passportServerUrl, postBody) {
    return httpPostSimple(urlJoin(passportServerUrl, "/issue/verify-ticket-by-id"), async (resText) => JSON.parse(resText), postBody);
}
